.dropdown-menu {
  color: black;
}
.date-range {
  display: flex;
}
//Range calender css
.range-datepicker {
  font-family: $theme-font-family;
  font-size: 11px;
  // width: 230px;
  // margin-left: 0.25rem;

  ngb-datepicker {
    border: 1px solid $theme-color-gray;
    border-radius: 8px;
    padding: 16px;
    width: auto;
    font-family: $theme-font-family;
    font-size: 11px;
    color: $theme-color-gray;
  }

  .ngb-dp-header {
    border-radius: 20px;
    padding-top: 15px;
  }

  .ngb-dp-months {
    justify-content: center;
    padding: 0 10px;
  }

  .ngb-dp-weekday {
    color: $theme-color-gray;
    font-size: 11px;
  }

  .ngb-dp-arrow {
    padding-top: 0.25rem;
    justify-content: flex-end;
  }

  .ngb-dp-arrow.right {
    justify-content: flex-start;
  }

  .ngb-dp-arrow-btn {
    padding: 0;
    width: 24px;
    height: 24px;
    margin: 0 1rem 0 1rem;
    border: none;
    border-radius: 8px;
    background-color: $theme-color-blue;
    color: #fff;
    line-height: 1.5;
  }

  .ngb-dp-navigation-chevron {
    position: relative;
    bottom: 1px;
    width: 0.5em;
    height: 0.5em;
    color: white;
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .ngb-dp-month-name {
    margin: auto;
    max-width: 8rem;
    min-width: 8rem;
    color: $theme-color-gray;
    font-size: 12px;
    font-weight: 600;
  }

  .btn-light:not([disabled]):not(.disabled).active:hover,
  .btn-light:not([disabled]):not(.disabled).active.active {
    background-color: $theme-color-blue;
    color: white;
  }

  .ngb-dp-weekdays {
    font-size: 12px;
    border-bottom: 0;
  }

  .ngb-dp-navigation-select {
    display: flex;
    flex: 1 1 6rem;
  }

  .ngb-dp-today {
    border: 1px solid $theme-color-blue;
    color: $theme-color-blue;
    background-color: transparent;
    border-radius: 4px;
  }

  .ngb-dp-month:first-child .ngb-dp-week {
    text-align: center;
    color: $theme-table;
  }

  .input-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
    column-gap: 10px;
  }

  .custom-day {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0;
    height: 1.5rem;
    width: 1.5rem;
    font-size: $theme-font-size;
    color: $theme-table;
    line-height: 1.5rem;
  }

  .custom-day:hover {
    background-color: $theme-color-blue;
    color: white;
  }

  .custom-day.focused {
    background-color: $theme-color-blue;
    color: white;
    height: 1.5rem;
    width: 1.5rem;
  }

  .custom-day.range {
    background-color: $theme-color-blue;
    color: white;
    border: 1px dashed rgba($theme-color-blue, 0.7);
    border-radius: 0;

    &.start-date {
      border-left: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.end-date {
      border-right: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .custom-day.faded {
    border-radius: 0;
    background-color: rgba($theme-color-blue, 0.3);
    border: 1px dashed rgba($theme-color-blue, 0.7);
    border-left: none;
  }

  // .ngb-dp-week .ngb-dp-day .range.faded:last-child{
  //     border-right: none;
  // }

  .custom-select {
    margin: 3px;
  }

  .btn-calendar {
    display: flex;
    align-items: center;
    height: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    img {
      height: 15px;
    }
  }

  .input-calendar {
    border: 1px solid $theme-color-gray;
    outline: none;
    border-radius: 4px;
    padding: 4px 9px;
    // margin: 0;
    height: 100%;
    width: 100%;
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 15px;
    column-gap: 10px;

    .btn {
      border: none;
      border-radius: 0.5rem;
      padding: 0.3rem 1.5rem;
      font-size: 0.9rem;

      &.btn-sm {
        padding: 0.25rem 1rem;
        font-size: 0.75rem;
      }
      &.btn-gray {
        background-color: $theme-color-green;
        color: #fff;

        &:hover {
          box-shadow: 0 0 3px 0 $theme-color-green;
        }
      }
      &.btn-orange {
        background-color: $theme-color-blue;
        color: #fff;

        &:hover {
          box-shadow: 0 0 3px 0 $theme-color-blue;
        }
      }
    }
  }
}

input + ngb-datepicker {
  border-radius: 0;
}

ngb-datepicker-month {
  .ngb-dp-week:not(.ngb-dp-weekdays):nth-child(2) {
    justify-content: flex-end;
  }

  .ngb-dp-week:not(.ngb-dp-weekdays):nth-last-child(2) {
    justify-content: flex-start;
  }
}
