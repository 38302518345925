.modal-content {
  border-radius: 30px;
  .card {
    margin-bottom: 0px;
    .btn-save {
      background-color: #7eb140;
      color: #ffffff;
      font-size: 12px;
      width: 130px;
      padding: 5px;
      border-radius: 7px;
      border: none;
    }
    .btn-cancel {
      background-color: #163a65;
      color:  #ffffff;
      font-size: 12px;
      width: 130px;
      padding: 5px;
      border-radius: 7px;
      border: none;
    }
    .btn-changes {
      width: 100px;
      padding: 5px;
      font-size: 13px;
      border-radius: 8px;
      font-weight: 600;
    }
    .reset-button {
      width: 150px;
      padding: 7px;
      border-radius: 8px;
      font-weight: 600;
      font-size: 13px;
      margin-top: 32px;
    }
    .btn-green {
      background-color: #7eb140 !important;
      color: white;
    }
    .btn-blue {
      background-color: #163a65 !important;
      color: white;
    }
    .btn-red {
      background-color: $theme-color-red;
      color: white;
    }
    .btn-pink {
      background-color: $theme-color-pink;
      color: white;
    }
    .btn-darkpink {
      background-color: $theme-color-darkpink;
      color: white;
    }
  }
}
@media screen and (max-width: 440px) and (min-width: 320px) {
  .modal-content .card .btn-changes {
    width: 116px !important;
  }
}
