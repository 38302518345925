$paddingX: 5px;

//***custom ngx datatable component***
.ngxdt {
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
  height: 100%;

  > * {
    // font-family: $font-roboto;
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
  }

  .ngxdt-title {
    display: flex;
    min-height: 24px;
    height: 24px;
    font-size: 17px;
    margin: 0 10px;
    font-weight: 600;
    color: $theme-color-blue;
    font-family: $theme-font-family;
  }

  .ngxdt-header {
    display: flex;
    // min-height:10%;
    // height: 100%;
    flex-wrap: wrap;
    width: 100%;
    // gap: 20px;
    column-gap: 10px;
    row-gap: 15px;

    .ngxdt-buttons {
      margin-left: auto;
      // position: relative;
      // right: 6px;

      button {
        border: none;
        border-radius: $border-radius-2 !important;
        padding: 0.2rem 0.75rem;
        font-size: 0.75rem;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &.btn-add {
          background-color: $theme-color-blue;
          font-weight: 400;
          font-family: $theme-font-family;
          color: $theme-white;

          img {
            height: 60%;
          }

          &:hover {
            outline: 1px solid rgba(rgb(5, 133, 246), 0.7);
          }
        }

        &.btn-export {
          background-color: $theme-color-gray;
          font-weight: 400;
          font-family: $theme-font-family;
        }
      }

      label{
        height: 24px;
        color: $table-data-color;
        font-size: 0.75rem;
        font-family: $theme-font-family;
        display: inline-flex;
        align-items: center;
        gap: 4px;

        input{
          background-color: $theme-color-darkblue;
        }
      }
    }

    .ngxdt-search {
      .ngxdt-search-wrapper {
        width: 240px;
        display: flex;
        outline: 1px solid $theme-color-blue;
        align-items: center;
        // height: calc(100% - 2px);
        height: 22px;
        padding: 0;
        margin: 0 9px;
        gap: 3px;
        border-radius: 8px;
        font-size: 0.75rem;

        i {
          padding: 6px 6px;
          color: $theme-bg-color;
          flex: 0 0 auto;
          background-color: $theme-color-blue;
          border-radius: 8px;
        }

        input {
          border: none;
          height: calc(100% - 4px);
          margin: 0;
          padding: 0;
          color: $search-color;
          flex: 1 1 auto;
          width: max-content;
          font-family: $theme-font-family;
          letter-spacing: normal;
        }

        .btn-search {
          margin: 0;
          padding: 0;
          background-color: transparent;
          outline: none;
          height: 100%;
          border: none;
          border-radius: 0 $border-radius-1 $border-radius-1 0;
          min-width: 10px;
          flex: 0 0 auto;
          width: max-content;
          margin-bottom: 10px;
        }

        button {
          margin: 0;
          padding: 0.25rem 0;
          background-color: transparent;
          outline: none;
          height: 100%;
          border: none;
          //border-radius: 0 $border-radius-1 $border-radius-1 0;
          flex: 0 0 auto;
          width: max-content;
          transition: all 0.3s ease;

          // i {
          // color: $search-color;
          // }

          &:hover {
            // background-color: $search-color;

            i {
              color: white;
            }
          }
        }
      }
    }

    .ngxdt-select {
      height: 24px;
      color: $table-data-color;
      font-size: 0.75rem;
      font-family: $theme-font-family;
      // flex: 1 1 auto;
      //margin: auto 15px;
      margin-right: 0;

      label {
        margin: 0;
        margin-right: 0.25rem;
      }

      select {
        height: 100%;
        width: fit-content;
        border-radius: 8px;
        padding: 0 7px;
        margin: 0;
        outline: none;
        border: 1px solid $theme-color-gray !important;
        color: $theme-color-black-2;
        font-size: 11px !important;
        cursor: pointer;
      }
      select:focus > option:checked {
        background: $theme-color-blue;
        color: #fff !important;
      }
      .date-range {
        display: flex;
        align-items: center;
      }
    }

    .ngxdt-select-option {
      column-gap: 17px;
    }
    .select-option {
      display: flex;
      column-gap: 20px;
      flex-wrap: wrap;
      row-gap: 13px;
    }
  }

  .ngxdt-body {
    //padding: 0.5rem 0 0 0;
    height: 100%;
    margin-top: -17px;

    > * {
      font-size: 11px;
      letter-spacing: initial;
      color: $theme-color-gray;
    }
  }
}

//***/custom ngx datatable component***

//***ngx datatable***
.ngx-datatable.bootstrap {
  height: 100%;

  & > .visible {
    height: inherit;
    display: flex;
    flex-direction: column;
  }

  .datatable-header {
    //width: 100% !important;
    background-color: $theme-color-blue;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 0 8px;
    width: calc(100% - 12px) !important;

    .datatable-header-inner {
      font-weight: 600;
      color: $theme-white;
      width: 100% !important;
    }

    .datatable-header-cell {
      padding: 0 0.75rem;
      border-bottom: 1px solid $theme-white;

      .datatable-header-cell-label {
        font-size: 12px;
        color: $theme-white;
        line-height: 40px;
      }

      //to display first column data in center
      &.make-center .datatable-header-cell-template-wrap {
        display: block;
        text-align: center;
      }

      [class^="datatable-icon-"]::before,
      [class*=" datatable-icon-"]::before {
        display: none !important;
      }
      img {
        width: 20px;
      }

      .header-text {
        position: relative;
        top: 9px;
      }
    }
  }

  .datatable-body {
    // background-color: rgba($theme-color-gray-1, 0.4);
    overflow: hidden auto !important;
    flex: 1 1 auto;
    margin:0px 8px;
    margin-bottom: 5px;
    min-height: 300px !important;
    width: calc(100% + -5px) !important;
    height: calc(100vh - 350px) !important;

    /* customize scrollbar */
    &::-webkit-scrollbar {
      width: 2px;
      height: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $theme-color-gray-1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $theme-color-gray-light;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $theme-color-gray-light;
    }

    .datatable-scroll {
      display: inline-block;
      overflow-y: hidden;
      overflow-x: hidden;
      //width: 100% !important;
      //padding: 0px 9px;
      width: 100% !important;
      //position: relative !important;

      // .datatable-row-wrapper {
      //   width: calc(100% - 2 * #{$paddingX}) !important;
      // }
    }

    .empty-row {
      color: #6c757d;
      text-align: center;
    }

    .datatable-body-row {
      $row-height: 55px; // set from html

      vertical-align: top;
      border-top: none;
      border-bottom: 1px solid #cecece;
      cursor: default !important;
      width: calc(100% - 8px) !important;
      height: calc(#{$row-height} - 4px) !important;

      border-radius: 6px;
      background-color: rgb(255, 255, 255) !important;
      color: $theme-color-black-2;
      box-shadow: 1px 3px 6px rgba(#000, 0.16);

      &.active {
        background-color: rgba($theme-color-gray-1, 0.7) !important;
        color: $theme-color-black-2;
      }

      &:hover,
      &[ng-reflect-is-selected="true"]:hover {
        background-color: rgba($theme-color-gray-1, 0.4) !important;
        color: $theme-color-black-2;
      }

      .datatable-body-cell {
        padding: 0 0.75rem;
        //line-height: 1.5;
        display: flex;
        align-items: center;
        justify-content: left;
        height: 50px !important;

        .btn-edit {
          background-color: $search-color;
          border: none;
          line-height: 1;
          font-size: 10px;
          padding: 3px 7px;
          margin-top: -3px;

          &:hover {
            background-color: $table-data-color;
          }
        }

        a {
          color: $theme-color-gray;
          letter-spacing: 0;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            color: $theme-color-orange;
          }
        }

        //to display column data in center
        &.make-center {
          justify-content: center;
        }
      }

      .datatable-scroll {
        display: inline-block;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100% !important;
        padding: $paddingX;
        position: relative !important;
        // min-height: fit-content;
        height: 100% !important;
        // max-height: 100% !important;
        width: calc(100% - 5px) !important;
      }
      // &.datatable-row-even:not(.active) {
      //   background-color: #fff;
      // }
    }
  }
  // .datatable-body-row .datatable-row-odd .some-class {
  //   width: calc(100% - 5px) !important;
  // }
  .datatable-footer {
    background-color: white;

    .datatable-footer-inner {
      justify-content: center !important;

      .page-size-control {
        position: absolute;
        right: 6px;
        color: $theme-light-blue;
        display: flex;
        justify-content: center;

        .page-size-dropdown {
          height: 24px;
          padding: 0 0.25rem;
          line-height: 22px;
          border-radius: 0;
          color: $theme-light-blue;
          font-weight: 600;
          border: solid $theme-light-blue;
          border-width: 1px 0 1px 1px;
          background-color: #fff;
        }

        .page-size-text {
          border: 1px solid $theme-light-blue;
          height: 24px;
          padding: 0 0.75rem;
          line-height: 22px;
          color: $theme-light-blue;
          font-size: $theme-font-size-sm;
        }
      }

      .pagination-control {
        .pagination {
          height: 100%;
          color: $theme-light-blue;
          justify-content: center;
        }

        .page-link {
          padding: 0 8px;
          color: $theme-light-blue !important;
          line-height: 22px;
          align-items: center;
          display: flex;
          justify-content: center;
          border: 1px solid $theme-color-blue;
          font-size: $theme-font-size-sm;

          span {
            font-size: 15px;
            color: $theme-light-blue !important;
          }
        }

        .page-item.active .page-link {
          background-color: $theme-color-gray-1 !important;
          border-color: $theme-light-blue;
          font-weight: 600;
        }
      }
    }
  }
}

.ngxdt-icon {
  height: 24px;

  img {
    height: 20px;
  }
}

.ngxdt-date-range {
  .ngxdt-date-range-wrapper {
    // width: 90px;
    width: 85px;
    display: inline-flex;
    outline: 1px solid $theme-color-blue;
    align-items: center;
    // height: calc(100% - 2px);
    height: 24px;
    // padding: 0;
    // margin: 0 5px;
    border-radius: 3px;
    font-size: 0.75rem;

    input {
      border: none;
      height: calc(100% - 4px);
      // margin: 0;
      // padding: 0;
      color: $search-color;
      flex: 1 1 auto;
      //   width: max-content;
      font-family: $theme-font-family;
      letter-spacing: normal;
      // margin-left: 14px;
      font-size: 10px;
    }
    input::placeholder {
      color: $theme-color-blue;
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 540px) {
  .ngx-datatable.bootstrap .datatable-footer .datatable-footer-inner {
    justify-content: flex-start !important;
  }

  .ngxdt-select-option {
    margin-left: 9px;
  }
  .ngxdt .ngxdt-header .select-option {
    margin-left: 10px !important;
  }

  .mt-14 {
    margin-top: 14rem !important;
  }
  .ngxdt .ngxdt-body {
    margin-top: 0;
  }
  .ngxdt .responsiveheader {
    column-gap: 50%;
  }
  .ngxdt .responsive-header {
    column-gap: 10%;
  }
  .ngxdt .ngxdt-buttons {
    right: 25px;
    position: absolute;
    margin-right:0px;
}
.ngxdt .ngxdt-header .ngxdt-select select{
  padding: 0 22px;
}
.ngxdt .ngxdt-body {
  padding: 0.95rem 0 0 0;
}
.ngx-datatable.bootstrap .datatable-body {
  overflow: auto !important;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row{
  width: calc(100% - 7px) !important;
}
.ngx-datatable.bootstrap .datatable-body {
  overflow: auto !important;
  margin: 0px 0px 0px 8px;
  width: calc(100% - 12px) !important;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row{
  width: calc(100% - 1px) !important;
}
.datatable-header {
  margin: 0 0px 0px 8px;
  width: calc(100% - 12px) !important;
}
}

@media only screen and (max-width: 444px) and (min-width: 320px) {
  .ngxdt-select-option {
    gap: 14px !important;
  }
  .ngx-datatable.bootstrap
    .datatable-footer
    .datatable-footer-inner
    .page-size-control {
    position: relative;
  }
  .pagination-size-control {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column-reverse;
    gap: 15px;
  }
  .ngxdt .ngxdt-header .ngxdt-select {
    margin-left: 9px;
  }
  .ngxdt .ngxdt-header .ngxdt-select select {
    padding: 0 20px;
  }
  .ngxdt .ngxdt-header .ngxdt-buttons {
    margin-left: 10px;
  }
  .ngxdt .responsiveheader {
    column-gap: 50%;
  }
  .ngxdt .ngxdt-header .select-option {
    column-gap: 6px;
  }
  .ngx-datatable.bootstrap .datatable-body {
    overflow: auto !important;
  }
  .ngx-datatable.bootstrap .datatable-body {
    overflow: auto !important;
    margin: 0px 0px 0px 8px;
    width: calc(100% - 12px) !important;
  }
  .ngx-datatable.bootstrap .datatable-body .datatable-body-row{
    width: calc(100% - 1px) !important;
  }
  .datatable-header {
    margin: 0 0px 0px 8px;
    width: calc(100% - 12px) !important;
  }
}

@media only screen and (width: 320px) {
  .ngxdt .ngxdt-header .ngxdt-select select {
    padding: 0 10px !important;
  }
  .ngx-datatable.bootstrap .datatable-body {
    overflow: auto !important;
    margin: 0px 0px 0px 8px;
    width: calc(100% - 12px) !important;
  }
  .ngx-datatable.bootstrap .datatable-body .datatable-body-row{
    width: calc(100% - 1px) !important;
  }
  .datatable-header {
    margin: 0 0px 0px 8px;
    width: calc(100% - 12px) !important;
  }
}

//***/ngx datatable***

//***ngx datatable customize page wise***
// .ngx-datatable.bootstrap:not(.report) {

//     .datatable-header .datatable-header-inner,
//     .datatable-body .datatable-body-row {
//         width: 100% !important;

//         .datatable-row-center {
//             width: 100% !important;

//             .datatable-header-cell:last-child {
//                 flex: 1 1 auto;
//             }
//         }
//     }
// }

// .ngxdt.report-filter .ngxdt-header .ngxdt-search .ngxdt-search-wrapper{
//   width:200px !important;
// }
//***/ngx datatable customize page wise***
