// colors
$theme-color-darkblue: #163a65;
$theme-color-blue: #23487A;
$theme-bg-color: #f9f7f9;
$theme-color-green:#7EB140;
$theme-color-lightgreen:#72BF44;
$theme-color-darkgreen:#047920;
$theme-color-red:#DC2638;
$theme-color-danger:#ED1556;
$theme-color-pink:#F17DB1;
$theme-color-black:#1F2026;
$theme-color-skyblue:#78CEE3;
$theme-color-darkpink:#D66DA0;

//main header left settings
$horizontal-header-wrapper-padding: 0 30px;
$horizontal-header-top-bottom-space: 8px;
$horizontal-header-size: 80px;
$page-content-padding-x: 30px;
$page-content-padding-y: 20px;

/* Font-size */
$theme-font-size-xxs:6px;
$theme-font-size-xs:8px;
$theme-font-size-sm:10px;
$theme-font-size:11px;
$theme-font-size-md:12px;
$theme-font-size-lg:14px;
$theme-font-size-xl:15px;
$theme-font-size-xxl:16px;
$theme-font-size-xxxl:18px;
$theme-font-size-20:20px;
$theme-font-size-24:24px;
$theme-font-size-30:30px;
$theme-font-size-40:40px;


/* border - border-radius */
$border-radius-1: 0.25rem;
$border-radius-2: 0.5rem;
$border-radius-3: 0.45rem;

/* font-family */
$theme-font-family: Poppins, sans-serif;



/* colors */
$theme-gray: #a1a1a1;
$theme-red : #EF6A65;
$theme-light-blue: #23487A;
$theme-color-orange: #FF4714;
$theme-color-gray: #707070;
$theme-color-gray-light: #a1a1a1;
$theme-color-gray-lighter: #dee2e6;
$theme-color-gray-1: #EFEFEF; 
$theme-color-black-1: #171717;
$theme-color-black-2:#1F2026;
$theme-content-background-color: #efefef;
$theme-white:#FFFFFF;



/* others */
$table-data-color: #707070;
$search-color:#707070;
$theme-table:#707070;
$radio-green: #C3DA8C;