.chart-container {
  box-shadow: 0 5px 25px rgb(0 0 0 / 10%) !important;
  margin-bottom: 20px !important;
  height: 100%;

  &.card {
    height: 300px;
    border-radius: 10px;
    .card-header {
      padding: 15px 20px 10px;
      font-weight: 600;
      color: $theme-color-blue;
      letter-spacing: 0.1px;
      font-size: 14px;
      line-height: 30px;
      vertical-align: middle;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .card-body {
      padding: 10px !important;
    }
  }

  .chart-wrapper {
    $chart-content-height: 70%;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .chart-content {
      flex: 0 0 $chart-content-height;
      max-height: $chart-content-height;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .chart-footer {
      flex: 0 0 calc(100% - #{$chart-content-height});
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .chart-data {
        width: 100%;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .chart-value {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;

        font-weight: 600;
        font-size: 24px;
        color: $theme-color-blue;
      }
    }
  }

  select.drop-btn {
    padding: 2px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: $theme-color-blue;
    border: 2px solid $theme-color-blue;
    cursor: pointer;
  }
  select:focus > option:checked {
    background: $theme-color-blue;
    color: #fff !important;
  }
  select:focus > option {
    font-size: 12px;
    font-weight: 600;
  }
}
