/** layout: header and content body **/
.page-wrapper.horizontal-wrapper .page-header {
  width: 100vw;
  .header-wrapper {
    padding: $horizontal-header-wrapper-padding;
    height: $horizontal-header-size;

    & > div {
      padding: $horizontal-header-top-bottom-space 0;
    }

    .header-logo-wrapper{
      height: 100% !important;
      width: auto !important;
      .logo-wrapper {
        display: block !important;
        height: 100% !important;
  
        a img {
          height: 100% !important;
        }
      }
    } 

    .left-header {
      flex: 1 1 auto !important;

      .mega-menu {
        margin: -$horizontal-header-top-bottom-space 0;
        display: block;
      }

      .mega-menu-container.horizontal-mega-menu {
        position: relative !important;
        top: 0;
        left: 0;
        min-width: 500px;
        width: calc(100vw - 400px);
        height: $horizontal-header-size;
        border-radius: 0;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0 auto;
        box-shadow: none;
        overflow-y: hidden;
        overflow-x: auto;

        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        .menu-item {
          padding: 0 15px;

          .link-section {
            .link-header {
              a{
                padding: 10px 5px 8px 5px;
                border-bottom: 2px solid transparent;

                .link-title {
                  font-weight: 500;
                  color: black;
                }

                &:hover {
                  .link-title {
                    font-weight: 500;
                    color: $theme-color-blue;
                  }
                }
              }
            }
          }

          &.active .link-section {            
            .link-header {
              a{
                border-bottom: 2px solid $theme-color-blue;

                .link-title {
                  font-weight: 600;
                  color: $theme-color-blue;
                }
              }
            }
          }

          
        }
      }
    }

    .nav-right {
      flex: 0 0 auto;
    }
  }
}
.page-wrapper .page-body-wrapper {
  background-color: $theme-bg-color !important;
  .page-body {
    min-height: calc(100vh - #{$horizontal-header-size}) !important;
    margin-top: $horizontal-header-size !important;
    margin-left: 0 !important;
    display: flex;
    padding: $page-content-padding-y $page-content-padding-x;

    main {
      flex: 1 1 auto;
      max-width: 100%;
    }
  }
}

/*profile*/
.profile-wrapper {
  .profile-media {
    flex-direction: row-reverse;

    img {
      border-radius: 50% !important;
    }
    .media-body {
      margin-left: 0 !important;
      margin-right: 15px !important;
      text-align: right !important;

      span {
        font-weight: 600 !important;
      }
    }
  }
}

/*content pages*/
.component-wrapper {
  padding: 20px;
  background: #eee url("../../images/ck/bg.png") repeat;
  min-height: 100%;
  border-radius: 47px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  & > div {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.96);
    flex: 0 0 auto;

    &:last-child {
      flex: auto;
    }

    .card:first-child {
      background-color: transparent;
      box-shadow: none;
      margin-bottom: 20px;

      .card-header {
        background-color: transparent;
      }
      .card-body {
        background-color: transparent;
        padding: 20px;
      }
      .card-footer {
        background-color: transparent;
      }
    }
  }
}


/***default scrollbar***/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;

  &:hover {
    width: 8px;
    height: 8px;
  }
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: none;
  border-radius: 3px;

  /* Handle on hover */
  &:hover {
    background: #ccc;
  }

  /* Handle on active */
  &:active {
    background: #888;
  }
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: none;
  border-radius: 3px;

  &:hover {
    width: 8px;
    height: 8px;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
}
/***end default scrollbar***/

/*** search input field ***/
::-webkit-search-cancel-button{
  background: url("../../images/icons/clear.svg") no-repeat 50% 50%;
}
/***end search input field ***/

/*** tablet devices ***/
@media only screen and (max-width: 768px) {
  $menuHeight: ($horizontal-header-size / 2) + 1px;
  $headerHeight: $horizontal-header-size + $menuHeight;

  .page-wrapper {

    .page-header .header-wrapper {
      position: relative !important;

      .left-header {
        position: absolute !important;
        top: $horizontal-header-size;
        width: 100%;
        margin-left: -$page-content-padding-x !important;
        background-color: $theme-bg-color !important;

        .mega-menu-container.horizontal-mega-menu{
          width: 100% !important;
          height: $menuHeight !important;
        }
      }

      .right-header.nav-right {
        .profile-nav{
          display: block !important;

          .profile-wrapper{
            .profile-media {
              img{
                width: inherit !important;
              }
              .media-body{
                display: block !important;
              }
            }
          }
          .profile-dropdown{
            z-index: 99 !important;
          }
        }
      }
    }

    .page-body-wrapper {
      .page-body {
        min-height: calc(100vh - #{$headerHeight}) !important;
        margin-top: $headerHeight !important;
        padding: calc($page-content-padding-y / 2) calc($page-content-padding-x / 3) !important;
      }
    }
  }
}

/*** mobile devices ***/
@media only screen and (max-width: 575.98px) {

  .page-wrapper {
    .page-body-wrapper{
      background-color: #fff !important;
      .page-body {
        padding: calc($page-content-padding-y / 2) calc($page-content-padding-x / 5) !important;
      }
    } 

    .page-header .header-wrapper {
      & > div {
        padding: 0 !important;
      }

      .logo-wrapper {
        display: block !important;

        a img {
          height: 40px !important;
        }
      }

      .left-header {
        background-color: #fff !important;
        margin-left: - calc($page-content-padding-x / 2) !important;
        text-align: center;

        .mega-menu{
          display: inline-block !important;
          margin: 0 !important;
          padding: 0 !important;

          .mega-menu-container.horizontal-mega-menu{
            min-width: 150px !important;
            justify-content: center !important;

            .mobile-menu {
              select.form-control{
                font-size: 14px !important;
                font-weight: 600 !important;
                border-radius: 12px !important;
                border: none !important;
                background-color: $theme-color-blue;
                color: #fff;
                outline: none;
                box-shadow: none;
                padding: 0.375rem 0.375rem !important;
                // appearance:none;
                // -moz-appearance:none; /* Firefox */
                // -webkit-appearance:none; /* Safari and Chrome */
                // &::-ms-expand{ /* Edge and IE*/
                //   appearance: none;
                // }

                &:focus{
                  outline: none;
                  box-shadow: none;
                  border: none !important;
                }
                option{                  
                  background-color: $theme-color-blue !important;
                  color: #fff !important;
                  font-size: 14px !important;
                  font-weight: 600 !important;

                  &:checked{
                    color: $theme-color-blue !important;
                    background-color: #fff !important;
                  }
                }
              }
            }
          }
        }        
      }

      .right-header.nav-right {
        .profile-nav{
          display: block !important;
          &.onhover-dropdown {
            &:hover {
              .onhover-show-div {
                opacity: 1 !important;
                transform: translateY(15px) !important;
                visibility: visible !important;
              }
            }
          }

          .profile-wrapper{
            .profile-media {
              img{
                width: inherit !important;
              }
              .media-body{
                display: block !important;
              }
            }
            .profile-dropdown{
              width: 50% !important;
              top: 50px !important;
              left: unset !important;
              z-index: 99 !important;
            }
          }
          
        }
      }

    }
  }
}



/* Custom Select wrapper */
.mobile-menu-select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: 12px;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;

  select {
    /* Reset Select */
    outline: 0;
    border: 0;
    box-shadow: none;

    /* customize */
    flex: 1;
    padding: 0.5em;
    color: #fff;
    background-color: $theme-color-blue;
    background-image: none;
    cursor: pointer;
  
    /* remove arrow */
    appearance:none;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    &::-ms-expand{ /* Edge and IE*/
      appearance: none;
    }
  }

  /* add select arrow */
  &::after {
    content: '\276F';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em;
    background-color: $theme-color-blue;
    transition: .25s all ease;
    pointer-events: none;
  }
  /* Transition */
  &:hover::after {
    color: #f39c12;
  }
}
