/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

@import "./assets/scss/custom/variables";
@import "./assets/scss/custom/theme";
@import "./assets/scss/custom/forms";
@import "./assets/scss/custom/button";
@import "./assets/scss/custom/modal";
@import "./assets/scss/custom/ngx-datatable";
@import "./assets/scss/custom/chart";
@import "./assets/scss/custom/date-range-picker";